export function standardSlider(selectorId, IntervalTime) {
  let currentCount = 0;
  let slideCurrent = () => {
    selectorId.children[currentCount].classList.remove('current');
    currentCount++;
    if (currentCount == selectorCount) {
      currentCount = 0;
    }
    selectorId.children[currentCount].classList.add('current');
  }

  let selectorCount = selectorId.childElementCount;
  setInterval(() => slideCurrent(),IntervalTime);
}
