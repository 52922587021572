import {attuchHeight} from "./common/sub_windowheight";
import {standardSlider} from "./common/sub_standard-slider";
// WINDOW FIT.
let commonWindowHeight = (window.innerHeight);
let renderSync = false;
// SLIDER.
const selectorId = document.getElementById('slider');
const IntervalTime = 5000;

// ELEMENT SETTING.
const SectionFirst = document.getElementById('services');
const SectionProduct = document.getElementById('products');
const SectionUsersvoice = document.getElementById('users-voice');
const SectionFaq = document.getElementById('faq');
const SectionStaff = document.getElementById('introduction');
const SectionInstagram = document.getElementById('instagram');
// END ELEMENT SETTING.


// SCROLL EVENT
let scrollEvent = () => {
  if (!renderSync) {
    requestAnimationFrame(function () {
      renderSync = false;

      let triggerContentService = SectionFirst.getBoundingClientRect().top - (commonWindowHeight/1.75);
      let scrlCountFirstView = SectionFirst.getBoundingClientRect().top;

      let triggerContentProduct = SectionProduct.getBoundingClientRect().top - (commonWindowHeight/1.75);
      let triggerContentUservoice = SectionUsersvoice.getBoundingClientRect().top - (commonWindowHeight/1.75);
      let triggerContentFaq = SectionFaq.getBoundingClientRect().top - (commonWindowHeight/1.75);
      let triggerContentStaff = SectionStaff.getBoundingClientRect().top - (commonWindowHeight/1.75);
      let triggerContentInstagram = SectionInstagram.getBoundingClientRect().top - (commonWindowHeight/1.75);
      // services trigger.
      if (triggerContentService <= 0) {
        if (!SectionFirst.classList.contains('active')) {
          SectionFirst.classList.add('active');
        }
      }


      // header and product trigger.
      if (triggerContentProduct <= 0) {
        if (!SectionProduct.classList.contains('active')) {
          SectionProduct.classList.add('active');
        }
      }

      // user voice trigger.
      if (triggerContentUservoice <= 0) {
        if (!SectionUsersvoice.classList.contains('active')) {
          SectionUsersvoice.classList.add('active');
        }
      }

      // faq trigger.
      if (triggerContentFaq <= 0) {
        if (!SectionFaq.classList.contains('active')) {
          SectionFaq.classList.add('active');
        }
      }

      // staff trigger.
      if (triggerContentStaff <= 0) {
        if (!SectionStaff.classList.contains('active')) {
          SectionStaff.classList.add('active');
        }
      }

      // Instagram trigger.
      if (triggerContentInstagram <= 0) {
        if (!SectionInstagram.classList.contains('active')) {
          SectionInstagram.classList.add('active');
        }
      }
    });
  }
};
// END SCROLL EVENT

// LOAD FIRST. MAIN FRAME.
window.addEventListener('load', function(){
  attuchHeight('slider');
  document.addEventListener('scroll', scrollEvent, {passive: true});
  standardSlider(selectorId, IntervalTime);
});
